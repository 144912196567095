<template>
    <section class="nyukai">
        <AppHeader :header-text="headerText" />
        <v-content>
            <NyukaiReservation v-if="formVisible == 'reservation'"
                               v-model="ruleFormReservation"
                               @selectContent="updateSelectContent"
                               @selectStore="updateSelectStore"
                               @selectTwiceAWeek="updateSelectTwiceAWeek"
                               @moveForm="(formName) =>{ formVisible = formName; if(formName == 'customer') ruleFormCustomer.seibetu = '',ruleFormCustomer.size = '' }"
                               :isSelectedTrial="isSelectedTrial"
                               :isSelectedJoin="isSelectedJoin"
                               :isSelectedTwiceAWeek="isSelectedTwiceAWeek"
                               :stores="stores"
                               :weeks="weeks"
                               :trials="trials"
                               :visits="visits" />
            <NyukaiCustomer v-if="formVisible == 'customer'"
                            v-model="ruleFormCustomer"
                            :storeId="ruleFormReservation.store"
                            @selectSex="getSizeData"
                            @moveForm="(formName) =>{ formVisible = formName  }"
                            :isSelectedTrial="isSelectedTrial"
                            :isSelectedJoin="isSelectedJoin"
                            :isSelectedTwiceAWeek="isSelectedTwiceAWeek"
                            :contactableHours="contactableHours"
                            :sizes="sizes" />
            <NyukaiConfirm v-if="formVisible == 'confirm'"
                           v-model="ruleFormConfirm"
                           @moveForm="(formName) =>{ formVisible = formName  }" />
            <NyukaiPayment v-if="formVisible == 'payment'"
                           v-model="ruleFormPayment"
                           @moveForm="(formName) =>{ formVisible = formName  }"
                           @postFormData="postFormData"
                           @createNyukaiArray="createNyukaiArray"
                           :ruleFormReservation="ruleFormReservation"
                           :ruleFormCustomer="ruleFormCustomer"
                           :isSelectedTrial="isSelectedTrial"
                           :isSelectedJoin="isSelectedJoin"
                           :isSelectedTwiceAWeek="isSelectedTwiceAWeek"
                           :admissionfee="admissionfee"
                           :annualfee="annualfee"
                           :monthlyfee="monthlyfee"
                           :monthlyfee1="monthlyfee1"
                           :monthlyfee2="monthlyfee2"
                           :swimsuit="swimsuit"
                           :cap="cap"
                           :bag="bag"
                           :trial="trial"
                           :bus1="bus1"
                           :bus2="bus2"
                           :isnowCalculate="isnowCalculate"
                           :totalprice="totalprice" />

                <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
        </v-content>
        <AppFooter />
    </section>
</template>
<script>
    import NyukaiReservation from '@/components/NyukaiReservation.vue';
    import NyukaiCustomer from '@/components/NyukaiCustomer.vue';
    import NyukaiConfirm from '@/components/NyukaiConfirm.vue';
    import NyukaiPayment from '@/components/NyukaiPayment.vue';
    import AppHeader from '@/components/AppHeader.vue';
    import AppFooter from '@/components/AppFooter.vue';

    export default {
        name: 'Nyukai',
        components: {
            NyukaiReservation,
            NyukaiCustomer,
            NyukaiConfirm,
            NyukaiPayment,
            AppHeader,
            AppFooter,
        },

        data: function () {
            return {
                headerText: 'キッズスイミング体験教室申込・入会予約',
                formVisible: "reservation",
                //体験又は入会を選択しているかどうか(申込内容による)
                isSelectedTrial: false,
                isSelectedJoin: false,
                isSelectedTwiceAWeek: false,
                //店舗データ
                stores: [],
                storesIndex: {},
                //体験日付
                trials: [],
                trialsIndex: {},
                //スイミング日程
                weeks: [],
                weeksIndex: {},
                //初回来店日
                visits: [],
                visitsIndex: {},
                //水着
                sizes: [],
                sizesIndex: {},
                //連絡可能時間帯
                contactableHours: [],
                contactableHoursIndex: {},
                //バス協力金
                buses: [],
                busesIndex: {},
                bus1: {},
                bus2: {},
                ruleFormReservation: {
                    select: '',
                    store: '',
                    trial: '',
                    selectweek: '1',
                    bus1: '',
                    bus2: '',
                    week1: '',
                    week2: '',
                    visit: "",

                    //invitationCode: null,
                    //discountValue: null,
                    //coupon: null
                },
                ruleFormCustomer: {
                    lastname: '',
                    firstname: '',
                    lastkana: '',
                    firstkana: '',
                    seibetu: '',
                    birthday: '',
                    birthyear: '',
                    birthmonth: '',
                    birthdate: '',
                    age: '',
                    parentlastname: '',
                    parentfirstname: '',
                    tel: '',
                    zip: '',
                    email: '',
                    schoolname: '',
                    schoolnone: [],
                    grade: '',
                    experience: '',
                    swimpower: '',
                    swimpowercheck: [],
                    region: '',
                    locality: '',
                    street: '',
                    size: '',
                    bus: '',
                    accountnumber: '',
                    contactableHour: '',
                },
                ruleFormConfirm: {
                    confirm1: false,
                    confirm2: false,
                    confirm3: false,
                    confirm4: false,
                    confirm5: false,
                    confirm6: false,
                    confirm7: false,
                    confirm8: false,
                    confirm9: false,
                    confirm10: false,
                    confirm11: false,
                    confirm12: false,
                    confirm13: false,
                    confirm14: false,
                    confirm15: false,
                    confirm16: false,
                    confirm17: false,
                    confirm18: false,
                    confirm19: false,
                    confirm20: false,
                    confirm21: false,
                    terms: false,
                    //vuexに入れるプロパティを決めるためのプロパティ
                    kind: "",
                },
                ruleFormPayment: {
                    payment: "",
                },
                admissionfee: {},//入会金
                annualfee: {},//年会費
                monthlyfee: {},//月会費
                monthlyfee1: {},//月会費1ヶ月目
                monthlyfee2: {},//月会費2ヶ月目
                swimsuit: {},//水着
                cap: {},//キャップ
                bag: {},//バッグ
                trial: {},//体験会費
                calculateCounter: 0,
                isnowCalculate: false,

                //coupon: {
                    //couponID: null,
                    //inviterMemberCD: null,
                    //discountValue: null,
                    //invitationCode: null,
                    //freeBag: null,
                    //freeCap: null,
                    //freeSuit: null,              
                //}, 

                snackbar: false,
                snackbarText: '',    

            }
        },
        methods: {
            initGetData: function () {

                //店舗取得
                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postStoreWithSwimming")

                    .then(response => {

                        //店舗データの格納
                        this.stores = response.data;
                        this.createIndex(this.stores, this.storesIndex, "storeID");


                        this.getBusData();    //バス協力金
                        this.getContactableHourData();    //連絡可能時間帯データ
                        /*店舗を初期選択無しにする
                        this.getTrialData();    //体験在庫
                        this.getSwimmingData();   //スイミング在庫
                        this.getVisitData();    //来店時在庫
                        */

                    })
                    .catch(response => console.log(response))


            },
            getContactableHourData() {
                //一応初期化
                this.contactableHours = [];
                this.contactableHoursIndex = {};

                this.axios.post("https://renaisswebapi.azurewebsites.net/api/KidsSwimmingEnrollment/postContactableHour")
                    .then(response => {
                        //取ってきたデータを格納
                        this.contactableHours = response.data;
                        this.createIndex(this.contactableHours, this.contactableHoursIndex, "ID");
                        console.log(this.contactableHours, this.contactableHoursIndex);

                    })
                    .catch(response => console.log(response))
            },
            getBusData() {
                //一応初期化
                this.buses = [];
                this.busesIndex = {};

                const postdata = {
                    "itemClassCD": "BF",
                };

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postItemsByItemClassCD", postdata)
                    .then(response => {
                        //取ってきたデータを格納
                        this.buses = response.data;
                        this.createIndex(this.buses, this.busesIndex, "itemCD");

                    })
                    .catch(response => console.log(response))
            },


            //体験日付を取ってくる処理
            getTrialData() {
                //初期化
                this.trials = [];
                this.trialsIndex = {};
                this.$set(this.ruleFormReservation, "trial", "");
                if (!this.ruleFormReservation.store) {
                    return false;
                }
                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postStockSwimmingTrial", { "storeID": this.ruleFormReservation.store, })
                    .then(response => {
                        var nowTime = new Date(new Date(new Date().toLocaleString({ timeZone: 'Asia/Tokyo' })));

                        var twoDateAdd = new Date(new Date(nowTime).setDate(new Date(nowTime).getDate() + 2));

                        for (var i = 0; i < response.data.length; i++) {

                            var item = response.data[i]
                            if (twoDateAdd.getTime() < new Date(item.datetimeStart).getTime()) {

                                item.datetimeStartFormat = this.$moment(item.datetimeStart).format('YYYY/MM/DD HH:mm');
                                this.trials.push(item);
                            }
                        }

                        this.createIndex(this.trials, this.trialsIndex, "stockDataID");

                    })
                    .catch(response => console.log(response))
            },
            getSwimmingData() {
                this.weeks = [];
                this.weeksIndex = {};
                this.$set(this.ruleFormReservation, "week1", "");
                this.$set(this.ruleFormReservation, "week2", "");

                if (!this.ruleFormReservation.store) {
                    return false;
                }

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postStockSwimming", { "storeID": this.ruleFormReservation.store, })

                    .then(response => {

                        for (var i = 0; i < response.data.length; i++) {
                            var item = response.data[i]
                            item.datetimeStartFormat = ["日", "月", "火", "水", "木", "金", "土"][new Date(item.datetimeStart).getDay()]
                                + "：" + this.$moment(item.datetimeStart).format('HH:mm') + " ～ " + this.$moment(item.datetimeEnd).format('HH:mm');
                        }

                        this.weeks = response.data;
                        this.createIndex(this.weeks, this.weeksIndex, "ID");

                    })
                    .catch(response => console.log(response))
            },
            getVisitData() {

                this.visits = [];
                this.visitsIndex = {};
                this.$set(this.ruleFormReservation, "visit", "");

                if (!this.ruleFormReservation.store) {
                    return false;
                }

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postStockVisit", { "storeID": this.ruleFormReservation.store, })

                    .then(response => {

                        for (var i = 0; i < response.data.length; i++) {
                            var item = response.data[i]
                            item.datetimeStartFormat = this.$moment(item.datetimeStart).format('YYYY/MM/DD HH:mm');

                        }
                        this.visits = response.data;
                        this.createIndex(this.visits, this.visitsIndex, "ID");

                    })
                    .catch(response => console.log(response))
            },
            //性別選択時に取ってくる
            getSizeData() {
                this.sizes = [];
                this.sizesIndex = {};
                this.ruleFormCustomer.size = '';

                if (!this.ruleFormCustomer.seibetu) {
                    return false;
                }

                var itemClassCD = "";
                if (this.ruleFormCustomer.seibetu == "男") {
                    itemClassCD = "SSM";
                } else {
                    itemClassCD = "SSF";
                }
                var postData = {
                    "itemClassCD": itemClassCD, "店舗ＣＤ": this.ruleFormReservation.store,
                }
                console.log(postData);
                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postItemsByItemClassCD", postData)
                    .then(response => {
                        this.sizes = response.data;
                        this.createIndex(this.sizes, this.sizesIndex, "itemCD");
                    })
                    .catch(response => console.log(response))
            },           


            //IDからindex番号を探し出すためのObjectを作成
            createIndex: function (Array, IndexObject, IDName) {

                for (var i = 0; i < Array.length; i++) {

                    var item = Array[i];

                    IndexObject[item[IDName]] = i;

                }
            },
            getStoreByID: function (storeID) {
                return this.stores[this.storesIndex[storeID]];
            },
            getContactableHourByID: function (contactableHourID) {
                return this.contactableHours[this.contactableHoursIndex[contactableHourID]];
            },
            getBus: async function () {
                //バスを使用するstatus値(value)から回数への変換
                var busNumberByCode = {
                    "-1": 0,
                    "0": 1,
                    "1": 1,
                    "2": 2,
                };
                var usingBusNumber = busNumberByCode[this.ruleFormReservation.bus1];
                //週二回の場合は二回目の回数を足す
                if (this.isSelectedTwiceAWeek) {
                    usingBusNumber += busNumberByCode[this.ruleFormReservation.bus2];
                }

                var itemCD;
                if (usingBusNumber == 1) {
                    itemCD = "T005"
                } else if (usingBusNumber == 2) {
                    itemCD = "T006"
                } else if (usingBusNumber == 3) {
                    itemCD = "T007"
                } else if (usingBusNumber == 4) {
                    itemCD = "T008"
                }

                //バス一ヶ月目
                var bus1FeePost = new this.$store.state.GetItemByKindParamObject({
                    kind: this.$store.state.getItemKind.bus1,
                    busCount: usingBusNumber,
                });
                var bus1Fee = await this.getItemByKind(bus1FeePost);
                this.$set(this, "bus1", bus1Fee);

                //バス二ヶ月目
                var bus2FeePost = new this.$store.state.GetItemByKindParamObject({
                    kind: this.$store.state.getItemKind.bus2,
                    busCount: usingBusNumber,
                });
                var bus2Fee = await this.getItemByKind(bus2FeePost);
                this.$set(this, "bus2", bus2Fee);


                //return this.buses[this.busesIndex[itemCD]];
            },
            getTrialByID: function (stockDataID) {
                return this.trials[this.trialsIndex[stockDataID]];
            },
            getWeekByID: function (ID) {
                return this.weeks[this.weeksIndex[ID]];
            },
            getVisitByID: function (ID) {
                return this.visits[this.visitsIndex[ID]];
            },
            getSizeByID: function (itemCD) {
                return this.sizes[this.sizesIndex[itemCD]];
            },
            getTotalPrice: async function () {
                //計算中フラグ
                this.isnowCalculate = true;
                this.calculateCounter = 0;

                //選択データの初期化
                this.admissionfee = {};//入会金
                this.annualfee = {};//年会費
                this.monthlyfee = {};//月会費
                this.swimsuit = {};//水着
                this.cap = {};//キャップ
                this.bag = {};//バッグ
                this.trial = {};//体験会費

                //入会
                if (this.isSelectedJoin) {
                    //入会金
                    var entoryFeePost = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.entoryFee,
                    });
                    var entoryFee = await this.getItemByKind(entoryFeePost);
                    this.$set(this, "admissionfee", entoryFee);

                    //年会費
                    var annualFeePost = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.annualFee,
                        weeklyLessonCount: this.isSelectedTwiceAWeek ? 2 : 1,
                        storeID: this.ruleFormReservation.store,
                    });
                    var annualFee = await this.getItemByKind(annualFeePost);
                    this.$set(this, "annualfee", annualFee);


                    /*if (!this.isSelectedTwiceAWeek) {
                        this.getItemByItemCD("T003", "0", "monthlyfee");  //月会費週１

                    } else {
                        this.getItemByItemCD("T004", "0", "monthlyfee");  //月会費週２
                    }*/
                    //this.getMonthleeFeeByStoreID(this.ruleFormReservation.store);
                    //月会費
                    var monthlyFee1Post = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.monthlyfee1,
                        weeklyLessonCount: this.isSelectedTwiceAWeek ? 2 : 1,
                        storeID: this.ruleFormReservation.store,
                    });
                    var monthlyFee1 = await this.getItemByKind(monthlyFee1Post);
                    this.$set(this, "monthlyfee1", monthlyFee1);

                    var monthlyFee2Post = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.monthlyfee2,
                        weeklyLessonCount: this.isSelectedTwiceAWeek ? 2 : 1,
                        storeID: this.ruleFormReservation.store,
                    });
                    var monthlyFee2 = await this.getItemByKind(monthlyFee2Post);
                    this.$set(this, "monthlyfee2", monthlyFee2);


                    //水着
                    var swimsuitFeePost = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.swimsuit,
                        itemCD: this.ruleFormCustomer.size,
                        storeID: this.ruleFormReservation.store,
                        isFree: this.$store.state.coupon.freeSuitFlag
                    });
                    var swimsuitFee = await this.getItemByKind(swimsuitFeePost);
                    this.$set(this, "swimsuit", swimsuitFee);

                    //this.getItemByItemCD(this.ruleFormCustomer.size, this.ruleFormReservation.store, "swimsuit", this.$store.state.coupon.freeSuitFlag);  //水着

                    //スイミングキャップ
                    var capFeePost = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.cap,
                        itemCD: "T025",
                        storeID: null,
                        isFree: this.$store.state.coupon.freeCapFlag
                    });
                    var capFee = await this.getItemByKind(capFeePost);
                    this.$set(this, "cap", capFee);

                    //スクールバッグ
                    var bagFeePost = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.bag,
                        itemCD: "T024",
                        storeID: null,
                        isFree: this.$store.state.coupon.freebagFlag
                    });
                    var bagFee = await this.getItemByKind(bagFeePost);
                    this.$set(this, "bag", bagFee);

                    await this.getBus();    //バス協力金


                    //this.getItemByItemCD("T025", "0", "cap", this.$store.state.coupon.freeCapFlag);  //スイミングキャップ


                    //this.getItemByItemCD("T024", "0", "bag", this.$store.state.coupon.freeBagFlag);  //スクールバッグ


                    console.log("this is (before)");
                    console.log(this);                

                    //クーポン適用                    
                    /* if (this.$store.state.coupon.freeSuitFlag) { //水着
                        this.$set(this.swimsuit, "itemPrice", 0);                        
                        console.log("this.swimsuit is");
                        console.log(this.swimsuit);
                    }
                    
                    if (this.$store.state.coupon.freeCapFlag) { //キャップ
                        this.$set(this.cap, "itemPrice", 0);
                        console.log("this.cap is");
                        console.log(this.cap);                       
                    }
                    
                    if (this.$store.state.coupon.freeBagFlag) { //バッグ
                        this.$set(this.bag, "itemPrice", 0);
                        console.log("this.bag is");
                        console.log(this.bag);
                    }  */

                    console.log("this is (after)");
                    console.log(this);                    

                }
                //体験
                if (this.isSelectedTrial) {

                    this.getItemByItemCD("T032", "0", "trial");  //体験一日

                    //スクールバッグ
                    var trialFeePost = new this.$store.state.GetItemByKindParamObject({
                        kind: this.$store.state.getItemKind.trial,
                        itemCD: "T032",
                        storeID: null,
                    });
                    var trialFee = await this.getItemByKind(trialFeePost);
                    this.$set(this, "trial", trialFee);
                }
            },
            
            getItemByItemCD(itemCD, storeID, keyname, isFree) {


                const postdata = {
                    "商品ＣＤ": itemCD,
                    "店舗ＣＤ": storeID,
                    "isFree": isFree,
                };

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postItemByItemCD", postdata)

                    .then(response => {

                        //各objectに格納して情報を保持
                        if (keyname != "") {
                            this.$set(this, keyname, response.data);
                        }

                        this.calculateCounter++;
                        this.judgeIsNowCalculate();

                    })
                    .catch(response => console.log(response))
            },
            getItemsByItemClassCD(itemClassCD, storeID) {

                const postdata = {
                    "itemClassCD": itemClassCD,
                    "店舗ＣＤ": storeID,
                };

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postItemsByItemClassCD", postdata)

                    .then(response => {
                        //入会金情報の保持
                        this.$set(this, "admissionfee", response.data[0]);
                        this.calculateCounter++;
                        this.judgeIsNowCalculate();

                    })
                    .catch(response => console.log(response))
            },
            async getItemByKind(post) {
                try {
                    const response = await this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/getItemByKind", post);
                    this.calculateCounter++;
                    this.judgeIsNowCalculate();
                    return response.data;  // response data を返す
                } catch (error) {
                    console.log(error);  // エラーをコンソールに表示
                    throw error;  // エラーを再スロー
                }
            },
            //計算中フラグを解除するか判断する
            judgeIsNowCalculate: function () {
                var judge = false;
                if (this.ruleFormReservation.select == 1) {
                    if (this.calculateCounter >= 1) judge = true
                } else if (this.ruleFormReservation.select == 2) {
                    if (this.calculateCounter >= 6) judge = true
                } else if (this.ruleFormReservation.select == 3) {
                    if (this.calculateCounter >= 7) judge = true
                }
                if (judge) {
                    this.isnowCalculate = false;
                    this.calculateCounter = 0;
                }
            },
            getMonthleeFeeByStoreID: function (storeID) {
                const postdata = {
                    "itemClassCD": "MF",
                    "店舗ＣＤ": storeID,
                };

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postItemsByItemClassCD", postdata)

                    .then(response => {
                        //入会金情報の保持
                        if (!this.isSelectedTwiceAWeek) {
                            this.$set(this, "monthlyfee", response.data[0]);
                        } else {
                            this.$set(this, "monthlyfee", response.data[1]);
                        }

                        this.calculateCounter++;
                        this.judgeIsNowCalculate();

                    })
                    .catch(response => console.log(response))
            },
            updateSelectContent: function (content) {
                content != 1 ? this.isSelectedJoin = true : this.isSelectedJoin = false;
                content != 2 ? this.isSelectedTrial = true : this.isSelectedTrial = false;
            },
            updateSelectTwiceAWeek: function (selectweek) {
                selectweek == 1 ? this.isSelectedTwiceAWeek = false : this.isSelectedTwiceAWeek = true;
                console.log(this.isSelectedTwiceAWeek)
            },
            updateSelectStore: function (storeID) {
                //店舗でデータが異なる各マスタの更新
                this.getTrialData();//体験
                this.getSwimmingData();//レッスン時間
                this.getVisitData();    //来店時在庫

            },
            //合計金額確認ページ遷移時に送るログデータ
            postFormData: function () {

                var postdata = {
                    "Form1": JSON.stringify(this.ruleFormReservation),
                    "Form2": JSON.stringify(this.ruleFormCustomer),
                    "Form3": JSON.stringify(this.ruleFormPayment),
                    "totalprice": this.totalprice
                };

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollmentLogs/postForm", postdata)
                    .then(response => {

                    })
                    .catch(response => console.log(response))


            },
            createNyukaiArray: function () {
                this.$store.commit('resetNyukai');

                var store = this.getStoreByID(this.ruleFormReservation.store);
                var bus = this.bus1;
                //var swimsuit = this.$store.state.sizes[this.$store.state.selectedBusIndex];

                var weeks1 = this.getWeekByID(this.ruleFormReservation.week1);
                var weeks2 = this.getWeekByID(this.ruleFormReservation.week2);


                var firstvisit = this.getVisitByID(this.ruleFormReservation.visit);
                var trial1 = this.getTrialByID(this.ruleFormReservation.trial);

                var nyukaiReservation = this.ruleFormReservation;
                var nyukaiCustomer = this.ruleFormCustomer;
                var nyukaiPayment = this.ruleFormPayment;
                console.log(store, bus, weeks1, weeks2, firstvisit, trial1, nyukaiReservation, nyukaiCustomer, nyukaiPayment);
                //場合分けしない共通で入れるオブジェクト
                this.$store.commit('updateNyuakiEachParam', { name: "selectContents", value: nyukaiReservation.select });//申込み内容(postに必要なものではない)
                this.$store.commit('updateNyuakiEachParam', { name: "storeID", value: nyukaiReservation.store });
                if (store != undefined) {
                    this.$store.commit('updateNyuakiEachParam', { name: "storeName", value: store.storeName });//店舗名(postに必要なものではない)
                }
                this.$store.commit('updateNyuakiEachParam', { name: "memberName", value: nyukaiCustomer.lastname + "　" + nyukaiCustomer.firstname });
                this.$store.commit('updateNyuakiEachParam', { name: "memberKanaName", value: nyukaiCustomer.lastkana + "　" + nyukaiCustomer.firstkana });
                this.$store.commit('updateNyuakiEachParam', { name: "dateBirthDay", value: nyukaiCustomer.birthyear + "-" + nyukaiCustomer.birthmonth + "-" + nyukaiCustomer.birthdate });
                this.$store.commit('updateNyuakiEachParam', { name: "sex", value: nyukaiCustomer.seibetu });
                this.$store.commit('updateNyuakiEachParam', { name: "zipCode", value: nyukaiCustomer.zip });
                this.$store.commit('updateNyuakiEachParam', { name: "address", value: nyukaiCustomer.region + nyukaiCustomer.locality + nyukaiCustomer.street });
                this.$store.commit('updateNyuakiEachParam', { name: "address1", value: nyukaiCustomer.region });
                this.$store.commit('updateNyuakiEachParam', { name: "address2", value: nyukaiCustomer.locality });
                this.$store.commit('updateNyuakiEachParam', { name: "address3", value: nyukaiCustomer.street });


                this.$store.commit('updateNyuakiEachParam', { name: "cellPhone", value: nyukaiCustomer.tel });
                this.$store.commit('updateNyuakiEachParam', { name: "phoneMail", value: nyukaiCustomer.email });
                this.$store.commit('updateNyuakiEachParam', { name: "dateEnroll", value: this.$moment.utc().add(9, "H").format('YYYY/MM/DD') });
                this.$store.commit('updateNyuakiEachParam', { name: "payCatCD", value: nyukaiPayment.payment });//支払区分
                //M会員_SWIMMINGテーブル(スイミング特有補足事項)
                this.$store.commit('updateNyuakiEachParam', { name: "age", value: nyukaiCustomer.age });

                this.$store.commit('updateNyuakiEachParam', { name: "swimmingExp", value: nyukaiCustomer.experience });
                this.$store.commit('updateNyuakiEachParam', { name: "swimmingAbility", value: nyukaiCustomer.swimpowercheck.join(',') });
                this.$store.commit('updateNyuakiEachParam', { name: "parentName", value: nyukaiCustomer.parentlastname + "　" + nyukaiCustomer.parentfirstname });

                this.$store.commit('updateNyuakiEachParam', { name: "schoolName", value: nyukaiCustomer.schoolname });
                this.$store.commit('updateNyuakiEachParam', { name: "schoolYear", value: nyukaiCustomer.grade });
                //T仮売上伝票テーブル&T仮売上明細テーブル
                this.$store.commit('updateNyuakiEachParam', { name: "totalAmount", value: this.totalprice });//合計金額
                //入会週選択(postでは使わない
                this.$store.commit('updateNyuakiEachParam', { name: "numWithinAWeek", value: nyukaiReservation.selectweek });



                //申し込みに入会があるときに代入する処理
                if (this.isSelectedJoin) {
                    //入会金
                    this.$store.commit('updateNyuakiEachParam', { name: "itemCD_EF", value: this.admissionfee.itemCD });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemID_EF", value: this.admissionfee.itemID });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemName_EF", value: this.admissionfee.itemName });
                    this.$store.commit('updateNyuakiEachParam', { name: "quantity_EF", value: "1" });
                    this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_EF", value: this.admissionfee.itemPrice });
                    this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_EF", value: this.admissionfee.itemPrice });
                    //年会費
                    this.$store.commit('updateNyuakiEachParam', { name: "itemCD_AF", value: this.annualfee.itemCD });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemID_AF", value: this.annualfee.itemID });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemName_AF", value: this.annualfee.itemName });
                    this.$store.commit('updateNyuakiEachParam', { name: "quantity_AF", value: "1" });
                    this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_AF", value: this.annualfee.itemPrice });
                    this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_AF", value: this.annualfee.itemPrice });
                    //月会費
                    this.$store.commit('updateNyuakiEachParam', { name: "itemCD_MF", value: this.monthlyfee1.itemCD });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemID_MF", value: this.monthlyfee1.itemID });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemName_MF", value: this.monthlyfee1.itemName });
                    this.$store.commit('updateNyuakiEachParam', { name: "quantity_MF", value: "2" });
                    this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_MF", value: this.monthlyfee1.itemPrice * 2 });
                    this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_MF", value: this.monthlyfee1.itemPrice });

                    if (bus != undefined) {
                        //バス協力金(0回の場合busはundehined)
                        this.$store.commit('updateNyuakiEachParam', { name: "itemCD_BF", value: bus.itemCD });
                        this.$store.commit('updateNyuakiEachParam', { name: "itemID_BF", value: bus.itemID });
                        this.$store.commit('updateNyuakiEachParam', { name: "itemName_BF", value: bus.itemName });
                        this.$store.commit('updateNyuakiEachParam', { name: "quantity_BF", value: "2" });
                        this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_BF", value: bus.itemPrice * 2 });
                        this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_BF", value: bus.itemPrice });
                    }

                    //水着
                    this.$store.commit('updateNyuakiEachParam', { name: "itemCD_SS", value: this.swimsuit.itemCD });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemID_SS", value: this.swimsuit.itemID });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemName_SS", value: this.swimsuit.itemName });
                    this.$store.commit('updateNyuakiEachParam', { name: "quantity_SS", value: "1" });
                    this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_SS", value: this.swimsuit.itemPrice });
                    this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_SS", value: this.swimsuit.itemPrice });

                    //キャップ
                    this.$store.commit('updateNyuakiEachParam', { name: "itemCD_SC", value: this.cap.itemCD });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemID_SC", value: this.cap.itemID });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemName_SC", value: this.cap.itemName });
                    this.$store.commit('updateNyuakiEachParam', { name: "quantity_SC", value: "1" });
                    this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_SC", value: this.cap.itemPrice });
                    this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_SC", value: this.cap.itemPrice });

                    //バッグ
                    this.$store.commit('updateNyuakiEachParam', { name: "itemCD_SB", value: this.bag.itemCD });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemID_SB", value: this.bag.itemID });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemName_SB", value: this.bag.itemName });
                    this.$store.commit('updateNyuakiEachParam', { name: "quantity_SB", value: "1" });
                    this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_SB", value: this.bag.itemPrice });
                    this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_SB", value: this.bag.itemPrice });

                    //レッスン1日目
                    if (weeks1 != undefined) {
                        this.$store.commit('updateNyuakiEachParam', { name: "ID_day1", value: weeks1.ID });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeStart_day1", value: weeks1.datetimeStart });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeEnd_day1", value: weeks1.datetimeEnd });

                        //バス利用状況
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeBus_day1", value: weeks1.datetimeStart });

                    }
                    //レッスン2日目
                    if (weeks2 != undefined) {
                        this.$store.commit('updateNyuakiEachParam', { name: "ID_day2", value: weeks2.ID });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeStart_day2", value: weeks2.datetimeStart });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeEnd_day2", value: weeks2.datetimeEnd });

                        //バス利用状況
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeBus_day2", value: weeks2.datetimeStart });

                    }

                    //バス利用状況
                    this.$store.commit('updateNyuakiEachParam', { name: "statusBus_day1", value: nyukaiReservation.bus1 });
                    this.$store.commit('updateNyuakiEachParam', { name: "statusBus_day2", value: nyukaiReservation.bus2 });

                    if (firstvisit != undefined) {
                        //初回来店日
                        this.$store.commit('updateNyuakiEachParam', { name: "ID_visit", value: firstvisit.ID });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeStart_visit", value: firstvisit.datetimeStart });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeEnd_visit", value: firstvisit.datetimeEnd });
                        this.$store.commit('updateNyuakiEachParam', { name: "dateVisit", value: firstvisit.datetimeStart });

                    }
                }

                //クーポン
                this.$store.commit('updateNyuakiEachParam', { name: "coupon", value: this.$store.state.coupon });
                this.$store.commit('updateNyuakiEachParam', { name: "discountValue", value: this.$store.state.discountValue });                      
                

                //申し込みに体験があるときに代入する処理
                if (this.isSelectedTrial) {

                    //体験費用
                    this.$store.commit('updateNyuakiEachParam', { name: "itemCD_TF", value: this.trial.itemCD });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemID_TF", value: this.trial.itemID });
                    this.$store.commit('updateNyuakiEachParam', { name: "itemName_TF", value: this.trial.itemName });
                    this.$store.commit('updateNyuakiEachParam', { name: "quantity_TF", value: "1" });
                    this.$store.commit('updateNyuakiEachParam', { name: "subtotalAmount_TF", value: this.trial.itemPrice });
                    this.$store.commit('updateNyuakiEachParam', { name: "salesUnitPrice_TF", value: this.trial.itemPrice });

                    var contactableHour = this.getContactableHourByID(this.ruleFormCustomer.contactableHour);
                    //連絡可能時間帯
                    this.$store.commit('updateNyuakiEachParam', { name: "contactableHour", value: nyukaiCustomer.contactableHour });
                    if (contactableHour != undefined) {
                        this.$store.commit('updateNyuakiEachParam', { name: "contactableHourName", value: contactableHour.name });//連絡可能時間帯名(postに必要なものではない)
                    }

                    if (trial1 != undefined) {
                        //体験コース(とりあえず一日だけでしのぐ)
                        this.$store.commit('updateNyuakiEachParam', { name: "ID_trial", value: trial1.stockDataID });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeStart_trial", value: trial1.datetimeStart });
                        this.$store.commit('updateNyuakiEachParam', { name: "datetimeEnd_trial", value: trial1.datetimeEnd });
                        this.$store.commit('updateNyuakiEachParam', { name: "dayArray", value: trial1.dayArray });

                    }
                  
                }

                this.$store.commit('updateNyuakiEachParam', { name: "appliCatCD", value: nyukaiReservation.select == 1 ? "0" : nyukaiReservation.select == 2 ? "1" : "2" });

                console.log(this.$store.state);

                var postdata = {
                    "kidsSwimLogId": this.$store.state.nyukaiObjectID,
                    "jsonObject": JSON.stringify(this.$store.state.nyukai)
                };
                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollmentLogs/postLog", postdata)
                    .then(response => {

                    })
                    .catch(response => console.log(response))



                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollmentLogs/postObject", postdata)

                    .then(response => {

                        this.$store.commit('updateNyukaiObject', response.data);


                    })
                    .catch(response => console.log(response))



            }




        },
        created: function () {


            //最初に店舗などのデータを取ってくる処理
            this.initGetData();

        },
        watch: {
            formVisible: function (page) {
                if (page == "payment") {
                    this.postFormData();
                    this.getTotalPrice();
                }


            },
        },
        computed: {
            totalprice: function () {

                var result = 0;

                if (this.isSelectedJoin) {
                    if (this.$store.state.discountValue != undefined) result -= this.$store.state.discountValue; //クーポン割引
                    if (this.admissionfee.itemPrice != undefined) result += this.admissionfee.itemPrice;
                    if (this.annualfee.itemPrice != undefined) result += this.annualfee.itemPrice;
                    if (this.monthlyfee1.itemPrice != undefined) result += this.monthlyfee1.itemPrice;
                    if (this.monthlyfee2.itemPrice != undefined) result += this.monthlyfee2.itemPrice;
                    if (this.swimsuit.itemPrice != undefined) result += this.swimsuit.itemPrice;
                    if (this.cap.itemPrice != undefined) result += this.cap.itemPrice;
                    if (this.bag.itemPrice != undefined) result += this.bag.itemPrice;
                    if (this.bus1 != undefined) result += this.bus1.itemPrice;
                    if (this.bus2 != undefined) result += this.bus2.itemPrice;

                }
                if (this.isSelectedTrial) {
                    if (this.trial != undefined) result += this.trial.itemPrice;
                }

                return result;
            }
        }

    }
</script>

<style lang="scss" scoped>
    .nyukai {
        padding-bottom: 50px;
    }
</style>