<template>
    <section class="nyukai-main">
        <v-container class="fill-height" fluid>
            <v-row v-if="$store.state.isComplete" style="margin-top:5%;">
                <v-col cols="12" style="text-align:center!important;">
                    <p class="v-label theme--light">申し込みは完了しています</p>
                    <v-btn depressed
                           @click="applyNewly"
                           color="primary">
                        新たに申し込む
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="nyukai-main__contents" v-if="!$store.state.isComplete">
                <v-col cols="12" sm="8" md="4" class="nyukai-main__contents-text">
                    <v-card flat color="transparent">


                        <!-- step1 ご利用情報入力 -->
                        <v-form v-model="valid" ref="reservationForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="v-label theme--light">ご請求額</p>
                                        <v-row>
                                            <v-simple-table style="width:100%;">
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr v-if="isSelectedJoin">
                                                            <td><p class="v-label theme--light usedetail_text">入会金</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{admissionfee.itemPrice == undefined? 0:admissionfee.itemPrice}}</p></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr v-if="isSelectedJoin">
                                                            <td><p class="v-label theme--light usedetail_text">年会費</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{annualfee.itemPrice == undefined? 0:annualfee.itemPrice}}</p></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr v-if="isSelectedJoin">
                                                            <td><p class="v-label theme--light usedetail_text">月会費(2か月分)</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{(monthlyfee1.itemPrice == undefined? 0:monthlyfee1.itemPrice) + (monthlyfee2.itemPrice == undefined? 0:monthlyfee2.itemPrice) }}</p></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr v-if="isSelectedTrial">
                                                            <td><p class="v-label theme--light usedetail_text">体験費用</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{trial.itemPrice == undefined? 0:trial.itemPrice}}</p></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr v-if="isSelectedJoin">
                                                            <td><p class="v-label theme--light usedetail_text">水着</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{(swimsuit.itemPrice == undefined) ? 0 : swimsuit.itemPrice}}</p></td>
                                                            <td><p class="v-label theme--light usedetail_text red--text" v-if="isSelectedJoin && $store.state.coupon.freeSuitFlag">無料</p></td>
                                                        </tr>
                                                        <tr v-if="isSelectedJoin">
                                                            <td><p class="v-label theme--light usedetail_text">キャップ</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{(cap.itemPrice == undefined) ? 0 : cap.itemPrice}}</p></td>
                                                            <td><p class="v-label theme--light usedetail_text red--text" v-if="isSelectedJoin && $store.state.coupon.freeCapFlag">無料</p></td>
                                                        </tr>
                                                        <tr v-if="isSelectedJoin">
                                                            <td><p class="v-label theme--light usedetail_text">バッグ</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{(bag.itemPrice == undefined) ? 0 : bag.itemPrice}}</p></td>
                                                            <td><p class="v-label theme--light usedetail_text red--text" v-if="isSelectedJoin && $store.state.coupon.freeBagFlag">無料</p></td>
                                                        </tr>
                                                        <tr v-if="isSelectedJoin">
                                                            <td><p class="v-label theme--light usedetail_text">バス協力金(2か月分)</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{(bus1.itemPrice == undefined? 0:bus1.itemPrice) + (bus2.itemPrice == undefined? 0:bus2.itemPrice) }}</p></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr v-if="isSelectedJoin && $store.state.coupon.discountFlag && Boolean($store.state.discountValue)"> <!-- 割引有効かつ割引額設定あり -->
                                                            <td><p class="v-label theme--light usedetail_text red--text">割引</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">-{{$store.state.discountValue == undefined ? 0 : $store.state.discountValue}}</p></td>
                                                            <td></td>
                                                        </tr>                                                        
                                                        <tr>
                                                            <td><p class="v-label theme--light usedetail_text">合計金額</p></td>
                                                            <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{ isNaN(totalprice) ? '' : totalprice }}</p></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" id="ruleForm-payment" v-if="!isnowCalculate">
                                        <p class="v-label theme--light">お支払方法</p>
                                        <v-radio-group v-model="ruleForm.payment" @change="paymentChange()" :rules="rules.payment" row>
                                            <!--<v-radio label="クレジットカード" value="3"></v-radio>-->
                                            <!--<v-radio label="現金" v-if="!isSelectedTrial" value="0"></v-radio>-->
                                            <v-radio label="クレジットカード" v-if="isSelectedJoin" value="3"></v-radio>
                                            <v-radio label="現金" value="0"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <!--<v-row v-show="ruleForm.payment == 3">
                                    <v-col>
            <iframe frameborder="0" name="hss_iframe" width="98%" height="400px"></iframe>
            <form style="display: none" target="hss_iframe" name="form_iframe" method="post" action="https://securepayments.paypal.com/webapp/HostedSoleSolutionApp/webflow/sparta/hostedSoleSolutionProcess">
                <input type="hidden" name="cmd" value="_hosted-payment" />変数なし
                <input type="hidden" name="subtotal" :value="totalprice" />合計金額。セッションで渡してください
                <input type="hidden" name="business" value="TRT7PCDHJRCKE" />セキュアキー。変更しないでね
                <input type="hidden" name="paymentaction" value="sale" />カードポジション。変更しないでね
                <input type="hidden" name="currency_code" value="JPY" />決済通貨。SandBoxだとUSDしか機能しないよ。本番はJPYへ
                <input type="hidden" name="billing_first_name" :value="ruleFormCustomer.parentfirstname" />お客様の名。セッションで渡してね
                <input type="hidden" name="billing_last_name" :value="ruleFormCustomer.parentlastname" />お客様の氏。セッションで渡してね
                <input type="hidden" name="billing_zip" :value="ruleFormCustomer.zip" />お客様の郵便番号。セッションで渡してね
                <input type="hidden" name="billing_country" value="JP" />お客様のカード登録国。固定してもいいけど、本当はフォームから取得してセッションで渡すべき
                <input type="hidden" name="billing_state" :value="ruleFormCustomer.region" />お客様の都道県。セッションで渡してね
                <input type="hidden" name="billing_city" :value="ruleFormCustomer.locality" />お客様の市町村。セッションで渡してね
                <input type="hidden" name="billing_address1" :value="ruleFormCustomer.street" />お客様の市町村以下の住所。セッションで渡してね
                <input type="hidden" name="night_phone_a" value="81" />お客様の電話番語句国別コード。決裁上必須ではない
                <input type="hidden" name="night_phone_b" :value="ruleFormCustomer.tel" />お客様の電話番号。決裁上必須ではない
                <input type="hidden" name="email" :value="ruleFormCustomer.email" />お客様のメールアドレス。決裁上必須ではない
                <input type="hidden" name="template" value="templateD" />Paypalに引き渡す変数。固定で頼む
                <input type="hidden" name="return" :value="returnPageUrl" />決済後の戻りページ
            </form>

        </v-col>
    </v-row>-->
                                <v-row v-show="ruleForm.payment == 3">
                                    <v-col>
                                        <!-- <form action="https://agreeable-glacier-02c5b1d00.azurestaticapps.net/confirm" method="post" id="payment-form"> -->
                                        <form v-bind:action="returnPageUrl" method="post" id="payment-form">
                                            <div class="form-row">
                                                <label for="card-element" class="v-label theme--light">
                                                    クレジット・デビットカード番号
                                                </label>
                                                <div>
                                                    <v-text-field v-model="card.Name"
                                                                  label="名義"
                                                                  :rules="rules.Name"></v-text-field>
                                                </div>
                                                <div id="card-element" style="margin-top:20px;">

                                                    <!-- Stripe Element がここに入ります。 -->
                                                </div>

                                                <!-- Element のエラーを入れます。 -->
                                                <div id="card-errors" role="alert"></div>
                                                <div style="color:red;">{{errorMessage}}</div>
                                            </div>
                                        </form>
                                    </v-col>
                                    <v-col cols="12" style="text-align: center; margin-top: 30px;">
                                        <v-btn depressed color="primary"  style="margin-right:5%;" @click="paymentFormSubmit()">
                                            お支払い
                                        </v-btn>
                                        <v-btn depressed @click="returnForm" color="warning">戻る</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" style="text-align:center;">
                                        <v-btn depressed @click="returnThankYouPage" style="margin-right:5%;" color="primary" v-if="ruleForm.payment === '0'">申し込む</v-btn>
                                        <v-btn depressed @click="returnForm" color="warning" v-if="ruleForm.payment === '0'">戻る</v-btn>

                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>

                    </v-card>
                </v-col>

            </v-row>
        </v-container>

    </section>
</template>
<script>
    import { mapState } from 'vuex'
    //カード決済をする関数の変数
    var cardPayment;

    export default {

        components: {
        },
        model: {
            prop: 'ruleForm',
            event: 'changeFormValue'
        },
        props: {
            ruleForm: Object,
            ruleFormReservation: Object,
            ruleFormCustomer: Object,
            isSelectedJoin: Boolean,
            isSelectedTrial: Boolean,
            isSelectedTwiceAWeek: Boolean,
            admissionfee: Object,//入会金
            annualfee: Object,//年会費
            monthlyfee: Object,//月会費
            monthlyfee1: Object,//月会費1ヶ月目
            monthlyfee2: Object,//月会費2ヶ月目
            swimsuit: Object,//水着
            cap: Object,//キャップ
            bag: Object,//バッグ
            bus1: Object,//バス協力金
            bus2: Object,//バス協力金
            trial: Object,//体験会費
            isnowCalculate: Boolean,
            totalprice: Number,
        },

        data() {
            return {
                errorMessage: "",
                card: {
                    Name: "",
                },
                isCardSetting: false,
                paymentForm: "",
                //バリデーションに引っかかった時にヘッダーの分も上にしないと見えないため(createdで高さ取得)
                headerHeight: 0,
                valid: true,
                //returnPageUrl: "https://www.renaisskids.ga/confirm",
                returnPageUrl: this.$store.state.confirmUrl, //,"https://agreeable-glacier-02c5b1d00.azurestaticapps.net/confirm",
                //returnPageUrl: "http://localhost:8080/confirm",
                //計算中フラグ
                rules: {
                    payment: [v => { return this.blankRules(v, 0) },],
                    name: [v => { return this.blankRules(v, 1) },],
                },
                //全体をバリデーションした時にエラーが起こった一番上の項目へ飛ばすためのオブジェクト
                validationResult: [
                    { id: "ruleForm-payment", result: true },
                ],
                //validate: [],
                blankRules: function (v, index) { return !!v || this.changeValidationResult(index) || '必須です' },
            }
        },

        methods: {
            applyNewly: function () {
                window.open(this.$store.state.projectStarturl);
            },
            returnThankYouPage: function () {
                location.href = this.returnPageUrl;
            },
            //全体をバリデーションした時にどこがバリデーションクリアしないかを区別させる関数:validationResultにfalseを入れる
            changeValidationResult: function (index) {
                this.validationResult[index].result = false;
                //vuetifyのruleの仕様上return falseをしてその右の文字列まで評価させる必要がある
                return false;
            },
            //バリデーション通過しなかった時にその中でどの項目が一番上か、idを取ってくる処理
            getFalseValidationID: function () {
                var targetIndex = this.validationResult.length - 1;
                //配列の末尾から見て一番先頭の所をターゲットにする
                for (var i = this.validationResult.length - 1; i >= 0; i--) {
                    if (!this.validationResult[i].result) targetIndex = i;
                }
                return this.validationResult[targetIndex].id;
            },
            resetValidationResult: function () {
                //一度全部trueにする
                for (var i in this.validationResult) {
                    this.validationResult[i].result = true;
                }
            },
            returnForm: function () {

                //入会を選択しているかどうかで場合分け
                if (this.isSelectedJoin) {
                    this.$emit('moveForm', "confirm");

                } else {
                    this.$emit('moveForm', "customer");

                }

            },
            dispiframe: function () {
                //クレジット決済
                /* document.form_iframe.submit();
                 window.addEventListener('popstate', function () {
                     history.pushState(null, null, null);
                 });*/
            },
            paymentChange: function () {

                this.$emit('postFormData', "");

                if (this.ruleForm.payment == 3) {
                    this.dispiframe();
                }
                //入会のobject作成
                this.$emit('createNyukaiArray', "");

            },
            paymentFormSubmit: function () {

                /*var form = document.getElementById('payment-form');
                form.submit()*/

                cardPayment();

            },
            stripeSetting: function () {
                var publishableKey = "pk_live_51KXxCBHZrXUsl7dEUe2oKA5bA46uWwU93fitov3yX68Hk2cSAxIEpaZxH2ahWM8EWxi6f5KrLoLOCUvI3Nghiqgf00MX563OuH";

                const stripe = Stripe(publishableKey);
                var style = {
                    base: {
                        // ここでStyleの調整をします。
                        fontSize: '16px',
                        color: "#32325d",
                    }
                };


                const elements = stripe.elements();
                // card Element のインスタンスを作成
                const card = elements.create('card', { style: style, hidePostalCode: true });


                // マウント
                card.mount('#card-element');
                card.addEventListener('change', function (event) {
                    var displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

                // When the form is submitted...
                const form = document.getElementById('payment-form');
                //let submitted = false;


                var this_ = this;
                console.log(this);
                //カードペイメント変数に関数を代入して後で呼び出す
                cardPayment = function () {

                    if (this_.card.Name == "") {
                        alert("名義が入力されていません");
                        return false;
                    }


                    this_.axios.post(this_.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/cardPayment", {
                        req: {
                            currency: 'jpy',
                            paymentMethodType: 'card',
                        },
                        Amount: this_.totalprice,
                    })
                        .then(response => {

                            if (response.data == null) {
                                alert("通信に失敗しました");
                                return false;
                            }

                            var backendError = response.data.backendError;
                            var clientSecret = response.data.clientSecret;

                            console.log("カードレスポンス", response.data);

                            if (backendError) {
                                alert(backendError.message);
                                submitted = false;
                                //form.querySelector('button').disabled = false;
                                return;
                            }


                            // Confirm the card payment given the clientSecret
                            // from the payment intent that was just created on
                            // the server.
                            stripe.confirmCardPayment(clientSecret,
                                {
                                    payment_method: {
                                        card: card,
                                        billing_details: {
                                            name: this_.card.Name,
                                        },
                                    },
                                }).then(function (result) {
                                    if (result.error) {
                                        // Show error to your customer (e.g., insufficient funds)
                                        console.log(result.error.message);
                                        this_.errorMessage = result.error.message;
                                        alert(result.error.message);

                                    } else {

                                        this_.errorMessage = "";
                                        // The payment has been processed!
                                        if (result.paymentIntent.status === 'succeeded') {
                                            //submitted = false;
                                            //form.querySelector('button').disabled = false;
                                            console.log(result.paymentIntent);
                                            this_.returnThankYouPage();
                                        }
                                    }
                                });



                        })
                        .catch(function (error) {
                            if (error.response) {
                                // 要求がなされたとサーバがステータスコードで応答した
                                // 2XXの範囲外
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            } else if (error.request) {
                                // 要求がなされたが、応答が受信されなかった
                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                // http.ClientRequest in node.js
                                console.log(error.request);
                            } else {
                                // トリガーしたリクエストの設定に何かしらのエラーがある
                                console.log('Error', error.message);
                                alert(error.message);
                            }
                            console.log(error.config);
                            alert("通信に失敗しました")
                        });

                }

                /*var style = {
                    base: {
                        // ここでStyleの調整をします。
                        fontSize: '16px',
                        color: "#32325d",
                    }
                };

                var paymentRequest = stripe.paymentRequest({
                    country: 'JP',
                    currency: 'jpy',
                    total: {
                        label: '合計（デモ）',
                        amount: this.totalprice,
                    },
                    requestPayerName: true,
                });

                // card Element のインスタンスを作成
                var card = elements.create('card', { style: style, paymentRequest: paymentRequest, });


                // マウント
                card.mount('#card-element');
                card.addEventListener('change', function (event) {
                    var displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

                var form = document.getElementById('payment-form');
                this.paymentForm = form;
                form.addEventListener('submit', function (event) {
                    event.preventDefault();
                    stripe.createToken(card).then(function (result) {
                        console.log(result);
                        if (result.error) {
                            // エラー表示.
                            var errorElement = document.getElementById('card-errors');
                            errorElement.textContent = result.error.message;
                        } else {
                            // トークンをサーバに送信
                            //stripeTokenHandler(result.token);
                        }
                    });
                });*/
                this.isCardSetting = true;

            }
        },
        created() {
            //※支払方法を初期化(支払方法選択を追加させないと入会objectが作用しないので)
            this.$set(this.ruleForm, "payment", "");

        },
        mounted: function () {

            this.headerHeight = document.getElementById("RenaissHeader").clientHeight;

            window.scrollTo(0, 0);

            if (!this.isCardSetting) {
                this.stripeSetting();

            }
        },
        watch: {
            ruleForm: {
                handler: function () {
                    //親の値と同期をする
                    this.$emit('changeFormValue', this.ruleForm);
                },
                deep: true,
            },

        }



    }
</script>

<style lang="scss" scoped>
    .usedetail_text {
        text-align: right;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 0;
    }

    .nyukai-main__contents {
        justify-content: center;
    }

    .nyukai-radio {
        margin-top: 10px;

        @media only screen and (min-width:651px) {
            margin-top: 0px;
        }

        @media only screen and (min-width:961px) {
            margin-top: 0px;
        }
    }

    .confirm_checkbox {
        margin-bottom: 3%;
    }

    .usedetail_text {
        text-align: left;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 0;
    }
</style>
